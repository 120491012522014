import qs from "qs"
const auroraSolarWebAppUrl = import.meta.env.VITE_AURORA_SOLAR_WEBAPP_URL

export const defaultImage =
  "data:image/svg+xml;base64,PHN2ZyBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MDIgNTAwIiB2aWV3Qm94PSIwIDAgNTAyIDUwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMSAwaDUwMHY1MDBoLTUwMHoiIGZpbGw9IiNlOWVlZjEiLz48cGF0aCBkPSJtMzQwLjIgMTk2Ljd2MTA2LjZjMCA5LjgtNy41IDE3LjgtMTYuNyAxNy44aC0xNDQuOWMtOS4yIDAtMTYuNy04LTE2LjctMTcuOHYtMTA2LjZjMC05LjggNy41LTE3LjggMTYuNy0xNy44aDE0NC45YzkuMiAwIDE2LjcgOCAxNi43IDE3Ljh6bS0xNi44IDEwNC40di0xMDIuMmMwLTEuMi0uOS0yLjItMi4xLTIuMmgtMTQwLjdjLTEuMiAwLTIuMSAxLTIuMSAyLjJ2MTAyLjJjMCAxLjIuOSAyLjIgMi4xIDIuMmgxNDAuN2MxLjIgMCAyLjEtMSAyLjEtMi4yem0tMTAzLjEtNzQuOGMwIDguMi02LjIgMTQuOC0xMy45IDE0LjhzLTEzLjktNi42LTEzLjktMTQuOCA2LjItMTQuOCAxMy45LTE0LjggMTMuOSA2LjYgMTMuOSAxNC44em0tMjUgNDEuNSAxMy44LTE0LjZjMS42LTEuNyA0LjMtMS43IDUuOSAwbDEzLjggMTQuNiA0MS42LTQ0LjJjMS42LTEuNyA0LjMtMS43IDUuOSAwbDMwLjUgMzIuNHYyOS42aC0xMTEuNXoiIGZpbGw9IiNiYWJlYzEiLz48L3N2Zz4="

export function isMobile() {
  const navigatorIsMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  const screenIsMobile = window.innerWidth < 768
  return navigatorIsMobile || screenIsMobile
}

export function apiUrl(relative) {
  return import.meta.env.VITE_ROOT_API + relative
}

export function formatFullName(obj) {
  return (
    (obj.firstName ? `${obj.firstName} ` : "") +
    (obj.lastName ? `${obj.lastName}` : "")
  )
}

export function getUserDisplayName(user) {
  if (user.type === "BUSINESS") {
    return user.companyName
  } else {
    return formatFullName(user)
  }
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0")
}

export function extractTextFromHTMLString(htmlString) {
  return htmlString.replace(/<[^>]+>/g, " ")
}

export function formatCurrency(
  num,
  locale = "fr-FR",
  currency = "EUR",
  minimumFractionDigits = 2
) {
  if (isNaN(num)) {
    return num
  }
  return num.toLocaleString(locale, {
    style: "currency",
    currency,
    minimumFractionDigits,
  })
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000)

  var interval = seconds / 31536000

  if (interval > 2) {
    return Math.floor(interval) + " ans"
  }
  if (interval > 1) {
    return Math.floor(interval) + " an"
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + " mois"
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + " jour"
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + " heures"
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + " minutes"
  }
  return Math.floor(seconds) + " seconde"
}

export function timeDelta(firstDate, secondDate) {
  var seconds = Math.floor((secondDate - firstDate) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + " année"
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + " mois"
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + " jour"
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + " heures"
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + " minutes"
  }
  return Math.floor(seconds) + " seconde"
}

export function between(x, min, max) {
  return x >= min && x <= max
}

export function formatDate(date, withTime) {
  return withTime
    ? formatDateWithTime(date)
    : `${padTo2Digits(date.getDate())}-${padTo2Digits(
        date.getMonth() + 1
      )}-${date.getFullYear()}`
}

export function formatDateWithTime(date) {
  var hours = date.getHours()
  var minutes = date.getMinutes()
  minutes = minutes < 10 ? "0" + minutes : minutes
  var strTime = hours + ":" + minutes
  return (
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    "  " +
    strTime
  )
}

export function formatDateAsDayAndTime(input) {
  let date = new Date(input)
  // Determine if it is AM or PM
  return (
    date.toISOString().slice(8, 10) +
    "-" +
    date.toISOString().slice(5, 7) +
    " " +
    padTo2Digits(date.getHours()) +
    ":" +
    padTo2Digits(date.getMinutes())
  )
}

export function formatDateAsTime(date) {
  return padTo2Digits(date.getHours()) + ":" + padTo2Digits(date.getMinutes())
}
export function formatFullAddress(address) {
  return (
    (address.houseNumber ? `${address.houseNumber} ` : "") +
    (address.streetLine ? `${address.streetLine}, ` : "") +
    (address.district ? `${address.district}, ` : "") +
    (address.postcode ? `${address.postcode}` : "")
  )
}

export function formatFullAddressAsGmapsFormat(address) {
  return (
    (address.houseNumber ? `${address.houseNumber} ` : "") +
    (address.streetLine ? `${address.streetLine}, ` : "") +
    (address.city ? `${address.city}, ` : "") +
    (address.country ? `${address.country}` : "")
  )
}

export function addYears(date, years) {
  date.setFullYear(date.getFullYear() + years)
  return date
}

export function addMonths(date, months) {
  date.setMonth(date.getMonth() + months)
  return date
}

export function addDays(date, days) {
  date.setDate(date.getDate() + days)
  return date
}

export function addHours(date, hours) {
  date.setHours(date.getHours() + hours)
  return date
}

export function subtractDays(numOfDays, date = new Date()) {
  date.setDate(date.getDate() - numOfDays)
  return date
}

export function subtractMonths(numOfMonths, date = new Date()) {
  date.setMonth(date.getMonth() - numOfMonths)
  return date
}

export function subtractYears(numOfYears, date = new Date()) {
  date.setYear(date.getFullYear() - numOfYears)
  return date
}

export function isToday(date) {
  return new Date().toDateString() === date.toDateString()
}

export async function download(downloadUrl, name, params = {}) {
  const extensions = {
    "image/jpeg": ".jpeg",
    "image/png": ".png",
    "application/pdf": ".pdf",
  }
  const response = await axios.get(downloadUrl, {
    params,
    responseType: "blob",
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement("a")
  const contentType = response.headers["content-type"]
  const extension = extensions[contentType]
  const filename = name
    ? name.replace(/\.[^/.]+$/, "") + extension
    : "file" + extension
  link.href = url
  link.setAttribute("download", filename) //or any other extension
  document.body.appendChild(link)
  link.click()
}

export function formatHardwareEquipmentDisplayName(type) {
  let fullName = ""
  if (type.manufacturer)
    fullName = fullName + type.manufacturer.companyName + " - "
  return fullName + type.name
}

export function formatPresentationOptionDisplaySize(size) {
  if (size < 1000) return `${size} kWc`
  else return `${size / 1000} MWc`
}
export function isValNumber(val) {
  if (val || val === 0) {
    return true
  }
  return false
}

export function formatToArray(val) {
  return Array.isArray(val) ? val : [val]
}

export function validateFileType(files, accept) {
  let isValid = true

  for (var i = 0; i < files.length; i++) {
    let fileExtension = `.${files[i].name.split(".").pop().toLowerCase()}`
    if (!accept.includes(fileExtension)) {
      isValid = false
      break
    }
  }
  return isValid
}

export function getAuroraDesignCadUrl(projectId, designId) {
  return `${auroraSolarWebAppUrl}/projects/${projectId}/designs/${designId}/cad`
}

export function getAuroraDesignSalesModeUrl(projectId, designId) {
  return `${auroraSolarWebAppUrl}/projects/${projectId}/designs/${designId}/e-proposal/design`
}

export function isCrawler() {
  const crawlerRegex = /prerender/i
  if (navigator.userAgent && crawlerRegex.test(navigator.userAgent)) return true
  else return false
}

export function scrapeAddressComponent(place, componentName) {
  const component = place.address_components.filter((c) =>
    c.types.includes(componentName)
  )[0]

  return component ? component.long_name : null
}

export function getHourSlotsNotCovered(hourSlots) {
  let hourSlotsNotCovered = []
  const requiredHourSlots = Array.from(Array(24).keys())
  const formattedHourSlots = hourSlots.map((o) => {
    return {
      start: o.startHours + o.startMinutes / 60,
      end: o.endHours + o.endMinutes / 60,
    }
  })
  requiredHourSlots.forEach((hourSlot) => {
    if (
      !formattedHourSlots.some((o) => o.start <= hourSlot && hourSlot <= o.end)
    )
      hourSlotsNotCovered.push(hourSlot)
  })
  return hourSlotsNotCovered
}

export function isNullOrUndefined(value) {
  return value == null // true for null or undefined, false for anything else (including 0)
}
